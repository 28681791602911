import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
// import "./HooksGrid.css";
// import * as AllModules from "ag-grid-enterprise";
// import { ModuleRegistry } from "ag-grid-enterprise";

// ModuleRegistry.registerModules(Object.keys(AllModules));
const EmailToApp = (props) => {

    let data;
    const [autoGroupColumnDef] = useState({ minWidth: 200 })
    const [columnDefs] = useState([
        { headerName: "App Name", field: "app_name" },
        { headerName: "App ID", field: "app_id" },
        { headerName: "Mail Records", field: "mail_records" },
        { headerName: "Created By", field: "created_by" },
        { headerName: "Creators Url", field: "creators_url" },
        { headerName: "Created On Oldest", field: "created_on_oldest" },
        { headerName: "Created On Newest", field: "created_on_newest" },
    ]);
    const [defaultColDef] = useState({
        flex: 1,
        minWidth: 140,
        resizable: true,
        filter: true,
        sortable: true,
        enableRowGroup: props.user?.permissions?.data_interaction?.row_grouping ? true : false,
        enablePivot: props.user?.permissions?.data_interaction?.pivot_reporting ? true : false,
        enableValue: true,
    });

    const [gridLoading, setAgrid] = React.useState("Loading...");

    setTimeout(function () {
        setAgrid("No Data");
    }, 6000);

    data = props?.emails?.data

    return (
        <>
            <div style={{ width: "100%", height: "500px" }}>
                <div
                    id="myGrid"
                    style={{
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                    className="ag-theme-balham-dark"
                >
                    <AgGridReact
                        // modules={Object.keys(AllModules)}
                        columnDefs={columnDefs}
                        rowData={data}
                        defaultColDef={defaultColDef}
                        enableFilter={true}
                        enableSorting={true}
                        enableCharts={true}
                        //   rowGroupPanelShow={ props.user?.permissions?.data_interaction?.row_grouping ? "always" : "never"}
                        rowSelection="multiple"
                        loadingCellRenderer
                        debug={true}
                        //   sideBar={true}
                        overlayNoRowsTemplate={gridLoading}
                        enableCellTextSelection={true}
                        suppressAggFuncInHeader={true}
                        autoGroupColumnDef={autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                    // onGridReady={onGridReady}
                    ></AgGridReact>
                </div>
            </div>
        </>
    );

}

export default EmailToApp;
